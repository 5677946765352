import { CountUp } from 'countup.js';



let startArray = [];
export function counterInit() {

  let counters = document.querySelectorAll('.section_counter__value');

  counters.forEach(element => {
    let value = element.getAttribute('data-attr');
    let precent = element.getAttribute('data-precent');
    let counterID = element.id;

    let options = {
          useEasing: true, 
          useGrouping: true, 
          separator: ' ', 
          decimal: '.', 
          duration: 3,
          smartEasingThreshold: 100000,
          smartEasingAmount: 100,
          suffix: precent,
        };
    let start = new CountUp(counterID, value, options);

    startArray.push(start);
  })
}

export function counterStart() {
  startArray.forEach(element=>{
    element.start();
  })
}

export default {counterInit, counterStart}; 