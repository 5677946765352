let boxes = document.querySelectorAll('.section_peopleLoop__block');

function closepanel(panel) {
    
}

function startAction() {
    boxes.forEach((box)=> {
        box.classList.remove('active');
    })
    this.classList.add('active');
    
    
}

function eventInit() {
    
    boxes.forEach((box)=> {
        box.addEventListener('click', startAction);
    })
}



export function peopleCollapseInit() {
    eventInit();
}