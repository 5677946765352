
import * as L from 'leaflet';
//import 'leaflet'; // Works as well, because Leaflet still exports to window.L as well in version 1.3.1.
// import 'leaflet.markercluster';
//import {MarkerClusterGroup} from 'leaflet.markercluster';

export function mapInit() {
    jQuery(document).ready(function ($) {

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax.url,
            data: {
              action: 'get_mapdata'
            },
           
            success: function (response) {
              //
              console.log(response);

              initMap(response);
            },
            cache: false,
        });
    
        function initMap(data) {
            // position we will use later
            var lat = data.lat;
            var lon = data.lon;
            // initialize map
            var map = L.map('map-here', {
                center: [lat, lon],
                zoom: data.zoom,
                scrollWheelZoom: statusbar.scrollWheelZoom,
                zoomControl: false
            });

            var pinIcon = L.icon({
                iconUrl: '../wp-content/themes/flexible-theme/assets/img/pin.svg',
            
                iconSize:     [40, 48], // size of the icon
                iconAnchor:   [20, 48], // point of the icon which will correspond to marker's location
                popupAnchor:  [0, -48] // point from which the popup should open relative to the iconAnchor
            });
            // set map tiles source
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoienV6YW5uYWwiLCJhIjoiY2pxcnprN295MHE3NTQzcHI1czZhOW4yNSJ9.t0tQeWdB13_gNSR82lF6zA', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 20,
            }).addTo(map);
            // add marker to the map
            let marker = L.marker([lat, lon], {icon: pinIcon}).addTo(map);
            // add popup to the marker
            marker.bindPopup('<img style="width: 200px;" src="'+data.logo+'" alt="logo"/>').openPopup();
        }
        



});
} 