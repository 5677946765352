let voivodes = document.querySelectorAll('.voivodeship');
let selectVoivod = document.querySelector('#section_download_map__select');

function removeClassActive(element) {
    element.classList.remove('voivodeship--active');
}

function getList(place = 'pl_mz') {
    console.log(place);
    let post_id = document.getElementById('download_file').getAttribute('data-post-id');

    jQuery(document).ready(function ($) {

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: ajax.url,
            data: {
                action: 'get_filesList',
                'data-local': place,
                'data-post-id': post_id,
            },
            success: function (response) {
                console.log(response);
                if (response != '') {
                    generateList(response);
                }
            }
        });
    });
}

function generateList(data) {
    let main_div = document.createElement('div');
    main_div.classList.add('section_download_map__fileBox');
    let main_header = document.createElement('p');
    main_header.classList.add('section_download_map__fileBoxHeader');
    main_header.innerText = data.element_title;

    main_div.append(main_header);

    let main_subheader = document.createElement('p');
    main_subheader.classList.add('section_download_map__fileBoxSubHeader');
    main_subheader.innerText = "Deklaracje Właściwości Użytkownych aktualne!";

    main_div.append(main_subheader);

    data.element_prod.forEach( (element)=>{
        let div = document.createElement('div');
                    div.classList.add("section_download_map__product");

        let titles = document.createElement('P');
        titles.classList.add("section_download_map__productName");
        titles.innerText = element.product_name;
        div.append(titles);

        if(element.files) {
            element.files.forEach( (file)=> {
                let linkParagraph = document.createElement("P");
                linkParagraph.classList.add('section_download_map__linkParagraph');
    
                let link = document.createElement("a");
                link.classList.add('section_download_map__link');
                link.href = file.file;
                link.setAttribute('download',"download");
                // link.setAttribute('target',"_blank");
                link.innerText = file.file_name;
    
                linkParagraph.append(link);
    
                div.append(linkParagraph);
            })
        }


        main_div.append(div);
    } );


    let tabNav = document.createElement("div");
    tabNav.classList.add('section_download_map__tabNav');

    let tabWrapper  = document.createElement("div");
    tabWrapper.classList.add('section_download_map__tabWrapper');


    if(typeof(data.element_prod_arch) != 'undefined' && data.element_prod_arch != null) {
        
    
        if(data.element_prod_arch.products_archiv_repeater) {
            let tabNavHeader = document.createElement('H5');
            tabNavHeader.innerText = 'Dokumenty archiwalne';
            data.element_prod_arch.products_archiv_repeater.forEach( (element) => {
            
                let tabNavData = document.createElement('span');
                console.log(element.products_archiv_year);
                tabNavData.innerText = element.products_archiv_year;
                tabNavData.setAttribute('data-year', element.products_archiv_year);
                tabNavData.classList.add('section_download_map__tabNavSpan');
        
                tabNav.append(tabNavData);
        
                let tabBox = document.createElement('div');
                tabBox.classList.add('section_download_map__tabBox');
                tabBox.setAttribute('data-year', element.products_archiv_year);
        
        
                element.products_archiv_products.forEach( (product)=>{
                    let div = document.createElement('div');
                                div.classList.add("section_download_map__product");
            
                    let titles = document.createElement('P');
                    titles.classList.add("section_download_map__productName");
                    titles.innerText = product.products_archiv_product_name;
                    div.append(titles);
            
                    if(product.products_archiv_files) {
                        product.products_archiv_files.forEach( (file)=> {
                            let linkParagraph = document.createElement("P");
                            linkParagraph.classList.add('section_download_map__linkParagraph');
                
                            let link = document.createElement("a");
                            link.classList.add('section_download_map__link');
                            link.href = file.file;
                            link.setAttribute('download',"download");
                            // link.setAttribute('target',"_blank");
                            link.innerText = file.file_name + ' - ważny do: ' + file.data_exp;
                
                            linkParagraph.append(link);
                
                            div.append(linkParagraph);
                        })
                    }
                   
            
                    tabBox.append(div);
                } );
                tabWrapper.append(tabBox);

            } );
            tabNav.prepend(tabNavHeader);
        }

        

    }
   

    let box = document.getElementById('section_download_map__response');
    let archive = document.getElementById('section_download_map__archive');

    console.log(box);

    var child = box.lastElementChild;  
    while (child) { 
        box.removeChild(child); 
        child = box.lastElementChild; 
    } 

    var childArchive = archive.lastElementChild;  
    while (childArchive) { 
        archive.removeChild(childArchive); 
        childArchive = archive.lastElementChild; 
    } 
    
    box.append(main_div);

    archive.append(tabNav);
    archive.append(tabWrapper);
    archiveEventInit();



}

function archiveEventInit() {
    let spans = document.querySelectorAll('.section_download_map__tabNavSpan');

    spans.forEach((span)=> {
        span.addEventListener('click' , function(){
            let year = this.getAttribute('data-year');

            let boxes = document.querySelectorAll('.section_download_map__tabBox');

            boxes.forEach( (element) => {
                element.classList.remove('active');

                if( element.getAttribute('data-year') == year ) {
                    element.classList.add('active');
                }
            } )
        })
    })
}

function checkVoivode() {

    voivodes.forEach(removeClassActive);

    this.classList.add('voivodeship--active');

    let place = this.getAttribute('data-local');

    getList(place);

}

function selectChange() {
    let selectedValue = this.options[this.selectedIndex].value;

    getList(selectedValue);
}

function showTooltop(event, el) {

    // event.stopPropagation();



  

    el.addEventListener('mousemove', (mouseEvent)=> {
        // mouseEvent.stopPropagation();
        let xpos;
        let ypos;
        if (mouseEvent)
        {
            //FireFox
            let element = document.querySelector('#tooltip__wrapper');

            xpos = mouseEvent.pageX;
            ypos = mouseEvent.pageY;
            element.style.top = ypos - 1+"px";
            element.style.left = xpos+"px";
        }
        else
        {
            //IE
            xpos = window.event.pageX;
            ypos = window.event.pageY;

            element.style.top = ypos - 1+"px";
            element.style.left = xpos+"px";

        }
    })
    let local = el.getAttribute('data-local');
    let localName = '';

    switch(local) {

        case "pl_ds" : 
            localName = "dolnośląskie";
            break;
        case "pl_kp" : 
            localName = "kujawsko-pomorskie";
            break;
        case "pl_lu" : 
            localName = "lubelskie";
            break;
        case "pl_lb" : 
            localName = "lubuskie";
            break;
        case "pl_ld" : 
            localName = "łódzkie";
            break;
        case "pl_ma" : 
            localName = "małopolskie";
            break;
        case "pl_mz" : 
            localName = "mazowieckie";
            break;
        case "pl_op" : 
            localName = "opolskie";
            break;
        case "pl_pk" : 
            localName = "podkarpackie";
            break;
        case "pl_pd" : 
            localName = "podlaskie";
            break;
        case "pl_pm" : 
            localName = "pomorskie";
            break;
        case "pl_sl" : 
            localName = "śląskie";
            break;
        case "pl_sk" : 
            localName = "świętokrzyskie";
            break;
        case "pl_wp" : 
            localName = "wielkopolskie";
            break;
        case "pl_wn" : 
            localName = "warmińsko-mazurskie";
            break;
        case "pl_zp" : 
            localName = "zachodniopomorskie";
            break;

    }
    let element = document.querySelector('#tooltip__wrapper');
    let elementInner = document.querySelector('#tooltip__inner');
    elementInner.innerHTML = localName;
    element.classList.add('active');
}

function resetTooltip() {
    let element = document.querySelector('.tooltip__wrapper');
    let elementInner = document.querySelector('.tooltip__inner');

    elementInner.innerHTML = '';
    element.classList.remove('active');
}

function eventInit() {
    getList();

    voivodes.forEach((element)=>{
        element.addEventListener('click', checkVoivode);
        element.addEventListener('mouseover', (event)=>{
            showTooltop(event, element );
        });
        element.addEventListener('mouseleave', resetTooltip);
    });

    selectVoivod.addEventListener('change', selectChange);


}

export function downloadMapInit() {
    eventInit();
}