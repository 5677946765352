

export function navInit() {
    jQuery(document).ready(function ($) {
        //obsługa hamburgera i elementów menu
        "use strict";

        $('.c-hamburger').on('click', function () {
            ($('.header__nav_search').hasClass("is-active")) ? $('.header__nav_search').removeClass("is-active"): $('.header__nav_search').addClass("is-active");
            ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");

            ($('.header__nav_search').hasClass("is-active")) ? $('#logo_mobilne').html('<img src="/img/logo_czarne_mob.png" alt="">'): $('#logo_mobilne').html('<img src="/img/logo_biale_mob.png" alt="">');
        });

        $('.header__nav_search .menu li a').on('click', function () {
            ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");
            ($('.header__nav_search').hasClass("is-active")) ? $('.header__nav_search').removeClass("is-active"): $('.header__nav_search').addClass("is-active");

            ($('.header__nav_search').hasClass("is-active")) ? $('#logo_mobilne').html('<img src="/img/logo_czarne_mob.png" alt="">'): $('#logo_mobilne').html('<img src="/img/logo_biale_mob.png" alt="">');
        });
    });
}